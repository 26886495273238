<script setup lang="ts">
withDefaults(
  defineProps<{
    menu: boolean;
    close: boolean;
    logo?: 'scan' | 'quiz' | 'both';
    back?: boolean | object;
  }>(),
  {
    close: false,
    logo: 'scan',
  },
);
defineEmits<{
  (e: 'back'): void;
  (e: 'close'): void;
}>();

let interval: string | number | NodeJS.Timer;
const { t } = useI18n();
const storeScan = useScanScan();
const hasConnection = ref(true);
const pendingTests = computed(() => useQueue().pending);

onMounted(
  () =>
    (interval = setInterval(
      () => (hasConnection.value = !!navigator.onLine),
      1000,
    )),
);
onUnmounted(() => clearInterval(interval));
</script>

<template>
  <header class="relative p-4 shadow-lg">
    <div
      class="absolute left-0 right-0 top-0 flex h-6 items-center justify-center bg-blue-600 text-xs text-white shadow"
      v-if="pendingTests > 0"
    >
      <LoadingSpinner class="mr-2 h-4 w-4 animate-spin" />
      {{ t('header.syncing', { tests: pendingTests }) }}
    </div>

    <div class="flex items-center">
      <!-- Back link. -->
      <div class="flex-justify-start flex w-24 items-center gap-2">
        <button @click="$emit('back')" class="w-[24px]" v-if="back">
          <img src="/images/icons/angle-left.svg" class="h-[24px] w-[12px]" />
        </button>
        <div class="w-[24px]" v-else></div>

        <div
          v-if="!hasConnection"
          class="flex h-8 w-8 items-center justify-center rounded-full bg-red-500 text-white animate-scale"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            class="h-6 w-6"
            fill="currentColor"
          >
            <path
              d="M239 207C248.4 197.7 263.6 197.7 272.1 207L319.1 254.1L367 207C376.4 197.7 391.6 197.7 400.1 207C410.3 216.4 410.3 231.6 400.1 240.1L353.9 287.1L400.1 335C410.3 344.4 410.3 359.6 400.1 368.1C391.6 378.3 376.4 378.3 367 368.1L319.1 321.9L272.1 368.1C263.6 378.3 248.4 378.3 239 368.1C229.7 359.6 229.7 344.4 239 335L286.1 287.1L239 240.1C229.7 231.6 229.7 216.4 239 207V207zM144 480C64.47 480 0 415.5 0 336C0 273.3 40.07 219.1 96 200.2V200C96 107.2 171.2 32 264 32C314.9 32 360.4 54.6 391.3 90.31C406.2 83.68 422.6 80 440 80C506.3 80 560 133.7 560 200C560 206.6 559.5 213 558.5 219.3C606.5 240.3 640 288.3 640 344C640 416.4 583.4 475.6 512 479.8V480H144zM264 80C197.7 80 144 133.7 144 200L144 234.1L111.1 245.5C74.64 258.7 48 294.3 48 336C48 389 90.98 432 144 432H506.6L509.2 431.8C555.4 429.2 592 390.8 592 344C592 308 570.4 276.9 539.2 263.3L505.1 248.4L511.1 211.7C511.7 207.9 512 204 512 200C512 160.2 479.8 128 440 128C429.5 128 419.6 130.2 410.8 134.2L378.2 148.7L354.9 121.7C332.8 96.08 300.3 80 263.1 80L264 80z"
            />
          </svg>
        </div>
      </div>

      <!-- Logo -->
      <div class="flex flex-grow justify-center">
        <img
          src="/images/mq-logo.svg"
          class="h-[40px] w-[56px]"
          v-if="logo === 'scan'"
        />
        <svg
          v-else-if="logo === 'quiz'"
          class="h-[40px] w-[56px]"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 73 40"
        >
          <defs><path id="A" d="M8 .1h56v40H8z" /></defs>
          <clipPath id="B"><use xlink:href="#A" /></clipPath>
          <g clip-path="url(#B)">
            <path
              d="M44.5.1c-3.8 0-7.7 1.2-10.8 3.4-3.2 2.2-5.8 5.3-7.2 8.9C25 16 24.7 20.1 25.4 24s2.6 7.5 5.3 10.2c2.8 2.8 6.2 4.7 10 5.4s7.7.4 11.3-1.1a20.9 20.9 0 0 0 8.8-7.3C63 27.9 64 24.1 64 20.1c0-2.7-.5-5.2-1.4-7.7a21.34 21.34 0 0 0-4.2-6.5c-1.8-1.8-4-3.4-6.4-4.3-2.5-1-5-1.5-7.5-1.5zm0 34.3c-2.8 0-5.5-.8-7.8-2.4s-4.1-3.9-5.2-6.4c-1.1-2.7-1.3-5.5-.8-8.3s1.9-5.3 3.8-7.3 4.4-3.4 7.2-3.9c2.8-.6 5.5-.2 8.1.8 2.5 1.1 4.8 2.9 6.2 5.3 1.6 2.4 2.4 5.1 2.4 8 0 1.9-.4 3.7-1.1 5.4s-1.8 3.4-3 4.7c-1.3 1.3-2.9 2.4-4.6 3.1-1.6.7-3.4 1-5.2 1z"
              fill="#050606"
            />
            <path d="M43.5,20.8v19.3h18.7L43.5,20.8z" fill="#e71668" />
            <path
              d="M12.5.2c-1.2 0-2.4.5-3.2 1.3C8.4 2.4 8 3.7 8 4.9v30.5c0 1.2.5 2.4 1.3 3.3 1 .7 2 1.2 3.2 1.2s2.3-.5 3.1-1.3 1.3-2 1.3-3.3L17 4.9c0-1.2-.5-2.4-1.3-3.3-.8-.8-2-1.4-3.2-1.4zm0 37.1c-.4 0-.7-.1-1.1-.4-.4-.2-.6-.6-.7-1s-.2-.8-.1-1.2.2-.7.5-1.1c.2-.4.6-.5 1-.6s.7 0 1.1.1c.5.4.8.6 1 1s.4.7.4 1.1c0 .2 0 .5-.1.7s-.2.5-.5.6c-.1.2-.4.4-.6.5-.4.3-.6.3-.9.3zm0-30.9c-.4 0-.7-.1-1.1-.4-.4-.2-.6-.6-.7-1s-.2-.7-.1-1.2c.1-.4.2-.7.5-1.1.2-.4.6-.5 1-.6s.7 0 1.1.1.8.6 1.1 1c.2.4.4.7.4 1.1 0 .2 0 .5-.1.7a3.04 3.04 0 0 0-.5.7c-.2.1-.5.4-.7.4-.4.1-.6.3-.9.3z"
              fill="#17b4cd"
            />
            <path
              d="M36.2 35.3c0-1.2-.5-2.4-1.3-3.3-.8-.8-2-1.3-3.2-1.3H12.5c-1.2 0-2.4.5-3.2 1.3-.9.9-1.3 2.2-1.3 3.3s.5 2.3 1.3 3.1c.8 1 2 1.4 3.2 1.4h19.1c1.2 0 2.4-.5 3.2-1.3.9-.8 1.4-2 1.4-3.2zm-25.7 0c0-.4.1-.7.4-1.1.2-.4.5-.6 1-.7.4-.1.7-.2 1.2-.1a2.02 2.02 0 0 1 1.1.6c.4.4.5.6.6 1s0 .8-.1 1.2-.6.6-1 .8-.7.4-1.1.4c-.2 0-.5 0-.7-.1s-.5-.2-.6-.5c-.1-.1-.4-.4-.5-.6-.2-.3-.3-.6-.3-.9zm19.7 0c0-.4.1-.7.4-1.1.2-.4.6-.6 1-.7s.7-.2 1.2-.1a2.02 2.02 0 0 1 1.1.6c.2.2.5.6.6 1s0 .8-.1 1.2-.4.7-.7.8-.7.4-1.1.4c-.2 0-.5 0-.7-.1s-.5-.2-.6-.5c-.2-.2-.4-.4-.5-.6-.6-.3-.6-.6-.6-.9z"
              fill="#66499d"
            />
            <g fill="#fff">
              <path
                d="M10.5 35.3c0-.4.1-.7.4-1.1.2-.4.5-.6 1-.7.4-.1.7-.2 1.2-.1a2.02 2.02 0 0 1 1.1.6c.4.4.5.6.6 1s0 .8-.1 1.2-.6.6-1 .8-.7.4-1.1.4c-.2 0-.5 0-.7-.1s-.5-.2-.6-.5c-.1-.1-.4-.4-.5-.6-.2-.3-.3-.6-.3-.9zm19.7 0c0-.4.1-.7.4-1.1.2-.4.6-.6 1-.7s.7-.2 1.2-.1a2.02 2.02 0 0 1 1.1.6c.2.2.5.6.6 1s0 .8-.1 1.2-.4.7-.7.8-.7.4-1.1.4c-.2 0-.5 0-.7-.1s-.5-.2-.6-.5c-.2-.2-.4-.4-.5-.6-.6-.3-.6-.6-.6-.9zM12.5 6.4c-.4 0-.7-.1-1.1-.4-.4-.2-.6-.6-.7-1s-.2-.7-.1-1.2c.1-.4.2-.7.5-1.1.2-.4.6-.5 1-.6s.7 0 1.1.1.8.6 1.1 1c.2.4.4.7.4 1.1 0 .2 0 .5-.1.7a3.04 3.04 0 0 0-.5.7c-.2.1-.5.4-.7.4-.4.1-.6.3-.9.3z"
              />
            </g>
          </g>
        </svg>
        <img
          src="/images/hdi-logo.svg"
          class="h-[40px] w-[56px]"
          v-else-if="logo === 'both'"
        />
      </div>

      <!-- Menu and Connection status-->
      <div class="flex w-24 items-center justify-end gap-2">
        <Navigation v-if="menu === true" />
        <svg
          v-else-if="
            (storeScan.closable === true && storeScan.onClose) || close
          "
          class="h-10 w-10"
          @click="
            () => {
              if (storeScan.onClose) storeScan.onClose();
              $emit('close');
            }
          "
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="24" fill="#E80065" />
          <path
            d="M33.5 15C26.2753 22.2247 22.2247 26.2753 15 33.5"
            stroke="white"
            stroke-width="3"
          />
          <path
            d="M33.5 33.5C26.2753 26.2753 22.2247 22.2247 15 15"
            stroke="white"
            stroke-width="3"
          />
        </svg>
        <div class="h-[31px] w-[46px]" v-else></div>
      </div>
    </div>
  </header>
</template>
../stores/scan/scan
